import React, { Fragment, useEffect } from "react";
import farewellAudio from "../assets/audio/farewell.mp3";
import { Link } from "react-router-dom";
import M from "materialize-css/dist/js/materialize.min.js";

const Farewell = () => {
  useEffect(() => {
    M.Collapsible.init(document.querySelectorAll(".collapsible"));
  }, []);  
  return (
    <Fragment>
      <div className='row'>
        <div className='col s12'>
          <h4 className='center-align' style={headingStyle}>
            ¡Terminaste!
          </h4>
          <h5 className='center-align'>
            Esperamos lo hayas disfrutado y también aprendieras algo nuevo, como nosotros lo hacemos de cada participante. Es un honor poder compartir la
            historia del Cajón y para hacerlo cada vez mejor tu nos puedes ayudar simplemente compartiendo tu opinión sobre esta experiencia.
          </h5>
          <h5 className='center-align'>Ha sido un gusto tu visita.</h5>
          <blockquote style={blockquoteStyle}>
            <strong>PD</strong>: A pesar de terminar el recorrido, el camino hasta la entrada sigue siendo un sendero de cerro. Autocuidado y cautela por lo
            menos hasta la Zona Segura ;)
          </blockquote>
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          <blockquote className='mt10'>Haz pasado por la última estación del Sendero Naturaleza Humana</blockquote>
          <audio controls src={farewellAudio} preload='none' style={audioPlayerStyle}>
            Your browser does not support the
            <code>audio</code> element.
          </audio>
        </div>
      </div>
      <div className='row'>
        <div className='col s12 center'>
          <ul className='collapsible'>
            <li>
              <div className='collapsible-header'>
                <i className='material-icons'>dvr</i>Encuesta de Satisfacción
              </div>
              <div className='collapsible-body'>
                <a 
                  href="https://docs.google.com/forms/d/1FgyEePlRCY0oAuuUeJLb8D1mq0rQjzrm5XcNIcbaQfY/edit?usp=sharing"
                  target="_blank"
                  className="waves-effect waves-light btn-large black"><i className="material-icons right">dvr</i>Ir a encuesta</a>
              </div>
            </li>
            <li>
              <div className='collapsible-header'>
                <i className='material-icons'>book</i>Sitios de Interés
              </div>
              <div className='collapsible-body narrow'>
                <div class="collection left-align">
                  <a href="https://geoparquecajondelmaipo.cl/" target="_blank" class="collection-item">Geoparque Cajón del Maipo <i className="material-icons right">open_in_new</i></a>
                  <a href="https://www.filantropiacortessolari.cl/wp-content/uploads/2019/09/geoparque1.pdf" target="_blank" class="collection-item">Geositios Cajón del Maipo <i className="material-icons right">open_in_new</i></a>
                  <a href="http://www.arqueologia.cl/sitio/Andes/sitios.htm" target="_blank" class="collection-item">Hallazgos Arqueológicos <i className="material-icons right">open_in_new</i></a>
                  <a href="https://mesozoico-chileno.webnode.cl/cajon-del-maipo/" target="_blank" class="collection-item">Hallazgos Paleontológicos <i className="material-icons right">open_in_new</i></a>
                  <a href="https://www.bcn.cl/estanteriadigital/resultados_ec?terminos=Culturas%20ind%C3%ADgenas" target="_blank" class="collection-item">Culturas Indígenas <i className="material-icons right">open_in_new</i></a>
                  <a href="https://www.mnhn.gob.cl/sitio/" target="_blank" class="collection-item">Museo Nacional Historia Natural <i className="material-icons right">open_in_new</i></a>
                  <a href="http://conociendochile.com/c-region-metropolitana-de-santiago/cajon-del-maipo/" target="_blank" class="collection-item">Más información del Cajón. <i className="material-icons right">open_in_new</i></a>
                </div>  
              </div>
            </li>            
            <li>
              <div className='collapsible-header'>
                <i className='material-icons'>book</i>Bibliografía
              </div>
              <div className='collapsible-body narrow'>
                <div class="collection left-align">
                  <a href="https://www.instagram.com/fundeso_cajondelmaipo/?hl=es" target="_blank" class="collection-item">FUNDESO Cajón del Maipo (Instagram) <i className="material-icons right">open_in_new</i></a>
                  <a href="https://www.facebook.com/fundeso.cajon/" target="_blank" class="collection-item">FUNDESO Cajón del Maipo (Facebook) <i className="material-icons right">open_in_new</i></a>
                  <a href="https://www.hemingway.cl/products/el-patrimonio-del-cajon-del-maipo-huellas-de-naturaleza-historia-e-identidad" target="_blank" class="collection-item">Complemento informativo <i className="material-icons right">open_in_new</i></a>
                </div>  
              </div>
            </li>            
          </ul>
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          <div className='center'>
            <Link to='/menu' className='waves-effect waves-light btn-small black' style={menuItemStyle}>
              <i className='material-icons left'>menu</i>Menú
            </Link>
            <Link to='/' className='waves-effect waves-light btn-small black m10r'>
              <i className='material-icons left'>chevron_left</i>Ir al inicio
            </Link>            
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const headingStyle = {
  marginTop: "30px",
  marginBottom: "10px",
};

const audioPlayerStyle = {
  marginBottom: "10px",
};

const blockquoteStyle = {
  marginTop: "30px",
};

const menuItemStyle = {
  marginRight: "5px"
}

export default Farewell;
