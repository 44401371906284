import React, { Fragment, useEffect } from "react";
import FooterBtns from "./shared/FooterBtns";
import welcomeAudio from "../assets/audio/welcome.mp3";
import audioAnexo1 from "../assets/audio/audio-anexo-1.mp3";
import imgRecomendaciones from "../assets/images/recomendations/recomendaciones-grles.svg";
import mapaDondeEstamos from "../assets/images/mapa-donde-estamos.svg";
import mapaSendero from "../assets/images/mapa-sendero.svg";
import mapaSenderoDownload from "../assets/downloads/mapa-sendero-download.pdf";
import M from "materialize-css/dist/js/materialize.min.js";

const Intro = () => {
  useEffect(() => {
    M.Collapsible.init(document.querySelectorAll(".collapsible"));
    M.Materialbox.init(document.querySelectorAll(".materialboxed"));
  }, []);
  return (
    <Fragment>
      <div className='row'>
        <div className='col s12'>
          <h5 className='center-align' style={headingStyle}>
            Bienvenida y bienvenido al sendero naturaleza humana de Sureste. Escucha los audios, sigue las imágenes y disfruta de esta experiencia.
          </h5>
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          <blockquote className='mt10'>¡Hola!</blockquote>
          <audio controls src={welcomeAudio} preload='none' style={audioPlayerStyle}>
            Your browser does not support the
            <code>audio</code> element.
          </audio>
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          <blockquote className='mt10'>Recomendaciones</blockquote>
          <audio controls src={audioAnexo1} preload='none' style={audioPlayerStyle}>
            Your browser does not support the
            <code>audio</code> element.
          </audio>
        </div>
      </div>

      <div className='row'>
        <div className='col s12 center'>
          <ul className='collapsible'>
            <li>
              <div className='collapsible-header'>
                <i className='material-icons'>info</i>Recomendaciones
              </div>
              <div className='collapsible-body'>
                <img src={imgRecomendaciones} className='responsive-img' alt='Sureste' />
              </div>
            </li>
            <li>
              <div className='collapsible-header'>
                <i className='material-icons'>library_books</i>Ver ficha médica
              </div>
              <div className='collapsible-body'>
                <a 
                  href="https://docs.google.com/forms/d/1mTDps6fbjCoIBtpZC35bpIhXIHtEtyiSe8fSGql_RfE/edit?usp=sharing"
                  target="_blank"
                  className="waves-effect waves-light btn-large black"><i className="material-icons right">library_books</i>Ir a ficha médica</a>                
              </div>
            </li>            
            <li>
              <div className='collapsible-header'>
                <i className='material-icons'>place</i>Mapa donde estamos
              </div>
              <div className='collapsible-body narrow'>
                <img className='responsive-image materialboxed' src={mapaDondeEstamos} />
              </div>
            </li>
            <li>
              <div className='collapsible-header'>
                <i className='material-icons'>place</i>Mapa del sendero
              </div>
              <div className='collapsible-body narrow'>
                <img className='responsive-image materialboxed' src={mapaSendero} />
                <div className='center'>
                  <a 
                    href={mapaSenderoDownload}
                    target="_blank"
                    className="waves-effect waves-light btn-large black">
                      <i className="material-icons right">download</i>Descargar Mapa Sendero</a>
                </div>                
              </div>
            </li>
          </ul>          
        </div>
      </div>      

      <FooterBtns continueTo='/station-1' />
    </Fragment>
  );
};

const headingStyle = {
  marginTop: "30px",
  marginBottom: "10px",
};

const audioPlayerStyle = {
  marginBottom: "10px",
};

export default Intro;
