import React, { Fragment } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ScrollRestoration from 'react-scroll-restoration';
import Start from "./components/Start";
import Menu from "./components/Menu";
import Welcome from "./components/Welcome";
import TopBar from "./components/shared/TopBar";
import Station from "./components/stations/Station";
import Farewell from "./components/Farewell";

import "materialize-css/dist/css/materialize.min.css";
import "./App.css";

import { stations } from "./utils/functions";

const App = () => {
  return (
    <Router>
      <ScrollRestoration />
      <Fragment>
        <TopBar />
        <div className='container'>
          <Switch>
            <Route exact path='/' component={Start}></Route>
            <Route exact path='/menu' component={Menu} />
            <Route exact path='/welcome' component={Welcome} />
            <Route exact path='/station-1' render={() => <Station data={stations["station1"]} />}></Route>
            <Route exact path='/inter-station-1' render={() => <Station data={stations["interStation1"]} />}></Route>
            <Route exact path='/station-2' render={() => <Station data={stations["station2"]} />}></Route>
            <Route exact path='/inter-station-2' render={() => <Station data={stations["interStation2"]} />}></Route>
            <Route exact path='/station-3' render={() => <Station data={stations["station3"]} />}></Route>
            <Route exact path='/station-4' render={() => <Station data={stations["station4"]} />}></Route>
            <Route exact path='/station-5' render={() => <Station data={stations["station5"]} />}></Route>
            <Route exact path='/station-6' render={() => <Station data={stations["station6"]} />}></Route>
            <Route exact path='/station-7' render={() => <Station data={stations["station7"]} />}></Route>
            <Route exact path='/inter-station-3' render={() => <Station data={stations["interStation3"]} />}></Route>
            <Route exact path='/station-8' render={() => <Station data={stations["station8"]} />}></Route>
            <Route exact path='/farewell' component={Farewell} />
          </Switch>
        </div>
      </Fragment>
    </Router>
  );
};

export default App;
