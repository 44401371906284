import React from "react";
import Logo from "../shared/Logo";

const TopBar = () => {
  return (
    <nav style={navbarStyle} className='black'>
      <div className='nav-wrapper'>
        <a href='#' className='brand-logo'>
          <Logo />
        </a>
      </div>
    </nav>
  );
};

const navbarStyle = {
  height: "80px",
};

export default TopBar;
