import React, { Fragment } from "react";
import { Link } from "react-router-dom";

const Start = () => {
  return (
    <Fragment>
      <div className='row'>
        <div className='col s12'>
          <h4 className='center-align' style={h4Style}>
            ¡Estás a punto de vivir una experiencia inolvidable!
          </h4>
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          <div className='center'>
            <Link to='/menu' className='waves-effect waves-light btn-large black'>
              <i className='material-icons right'>play_arrow</i>Comenzar
            </Link>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const h4Style = {
  marginTop: "70px",
  marginBottom: "50px",
};

export default Start;
