import React from "react";
import { Link } from "react-router-dom";
import StationMenuItems from "../components/stations/StationMenuItems";
import welcomeIcon from "../assets/images/icons/welcome.svg";
import farewellIcon from "../assets/images/icons/farewell.svg";

const Menu = () => {
  return (
    <div>
      <ul className='collection menu'>
        <li className='collection-item avatar'>
          <Link to='/welcome'>
            <img src={welcomeIcon} alt="Introducción y Bienvenida" class="circle" />
            <span className='title'>Introducción</span>
            <p>
              <strong>BIENVENIDA</strong>
            </p>
          </Link>
          <Link to='/welcome' className='secondary-content'>
            <i className='material-icons'>keyboard_arrow_right</i>
          </Link>
        </li>
        <StationMenuItems />
        <li className='collection-item avatar'>
          <Link to='/farewell'>
            <img src={farewellIcon} alt="Palabras Finales" class="circle" />
            <span className='title'>Despedida</span>
            <p>
              <strong>Palabras Finales</strong>
            </p>
          </Link>
          <Link to='/farewell' className='secondary-content'>
            <i className='material-icons'>keyboard_arrow_right</i>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Menu;
