import audio2 from "../assets/audio/audio-2.mp3";
import audio3 from "../assets/audio/audio-3.mp3";
import audio4 from "../assets/audio/audio-4.mp3";
import audio5 from "../assets/audio/audio-5.mp3";
import audio6 from "../assets/audio/audio-6.mp3";
import audio7 from "../assets/audio/audio-7.mp3";
import audio8 from "../assets/audio/audio-8.mp3";
import audio9 from "../assets/audio/audio-9.mp3";
import audio10 from "../assets/audio/audio-10.mp3";
import audio11 from "../assets/audio/audio-11.mp3";
import audio12 from "../assets/audio/audio-12.mp3";
import audio13 from "../assets/audio/audio-13.mp3";
import audio14 from "../assets/audio/audio-14.mp3";
import audio15 from "../assets/audio/audio-15.mp3";
import audio16 from "../assets/audio/audio-16.mp3";
import audio17 from "../assets/audio/audio-17.mp3";
import audioAnexo2 from "../assets/audio/audio-anexo-2.mp3";
import station1Icon from "../assets/images/icons/station-1.svg";
import station2Icon from "../assets/images/icons/station-2.svg";
import station3Icon from "../assets/images/icons/station-3.svg";
import station4Icon from "../assets/images/icons/station-4.svg";
import station5Icon from "../assets/images/icons/station-5.svg";
import station6Icon from "../assets/images/icons/station-6.svg";
import station7Icon from "../assets/images/icons/station-7.svg";
import station8Icon from "../assets/images/icons/station-8.svg";
import interStation1Icon from "../assets/images/icons/inter-station-1.svg";
import interStation2Icon from "../assets/images/icons/inter-station-2.svg";
import stationV2Icon from "../assets/images/icons/station-v2.svg";

export const stations = {
  welcome: {
    title: "Welcome",
    subtitle: "Bosque Esclerófilo",
    icon: station1Icon,
    route: "/station-1",
    next_route: "/inter-station-1",
    paragraphs: [
      "En estos momentos estas en un bosque esclerófilo compuesto principalmente por Peumos, pero… ¿Qué es un bosque esclerófilo? ¿Existen más bosques como este en el mundo? ¿Cómo se formaron? Descubre estas respuestas y otros datos sobre el bosque más importantes de la zona central de Chile.",
    ],
    audios: [
      {
        name: "AUDIO 2",
        title: "El bosque esclerófilo en el Cajón del Maipo",
        media: audio2,
      },
      {
        name: "AUDIO 3",
        title: "La historia del bosque esclerófilo",
        media: audio3,
      },
    ],
  },  
  station1: {
    title: "Estación 1",
    subtitle: "Bosque Esclerófilo",
    icon: station1Icon,
    route: "/station-1",
    next_route: "/inter-station-1",
    paragraphs: [
      "En estos momentos estas en un bosque esclerófilo compuesto principalmente por Peumos, pero… ¿Qué es un bosque esclerófilo? ¿Existen más bosques como este en el mundo? ¿Cómo se formaron? Descubre estas respuestas y otros datos sobre el bosque más importantes de la zona central de Chile.",
    ],
    audios: [
      {
        name: "AUDIO 2",
        title: "El bosque esclerófilo en el Cajón del Maipo",
        media: audio2,
      },
      {
        name: "AUDIO 3",
        title: "La historia del bosque esclerófilo",
        media: audio3,
      },
    ],
  },
  interStation1: {
    title: "Estación Intermedia",
    subtitle: "Salvadores del Bosque",
    icon: interStation1Icon,
    route: "/inter-station-1",
    next_route: "/station-2",
    paragraphs: [
      "Esta detención es para agradecer a los cientos de voluntarios y voluntarias que controlaron el incendio forestal de diciembre del 2019 y recordar lo mucho que debemos cuidar este entorno.",
    ],
    audios: [
      {
        name: "AUDIO 4",
        title: "Tributo salvadores del bosque",
        media: audio4,
      },
    ],
  },
  station2: {
    title: "Estación 2",
    subtitle: "Formación del Valle",
    icon: station2Icon,
    route: "/station-2",
    next_route: "/inter-station-2",
    paragraphs: ["Y pensar que el valle que puedes ver a tus pies, alguna vez estuvo bajo el agua ¿Sabías eso?"],
    audios: [
      {
        name: "AUDIO 5",
        title: "Una larga historia escrita en las rocas y en los paisajes",
        media: audio5,
      },
      {
        name: "AUDIO 6",
        title: "La última glaciación y los primeros habitantes",
        media: audio6,
      },
    ],
  },
  interStation2: {
    title: "Estación Intermedia",
    subtitle: "Quebrada el Toro",
    icon: interStation2Icon,
    route: "/inter-station-2",
    next_route: "/station-3",
    paragraphs: [
      "No podemos dejar de mencionar lo que es una quebrada, en estos momentos estamos en la base de una. Pueden ser bastante imprevisibles si no se les observa, su actividad es tan dinámica como el clima, lo cual es cada vez más cambiante.",
    ],
    audios: [
      {
        name: "AUDIO 7",
        title: "Definición y eventos quebrada el toro",
        media: audio7,
      },
    ],
  },
  station3: {
    title: "Estación 3",
    subtitle: "Domo Naturaleza Humana",
    icon: station3Icon,
    route: "/station-3",
    next_route: "/station-4",
    paragraphs: [
      "Haz llegado a lo que podríamos decir, guardando las proporciones, el campamento base. Punto de reunión entre montañistas antes de subir o después de descender las altas cumbres. Aquí podrás encontrar comodidades que en el resto del recorrido no encontrarás.  En la Sala Domo también se expone el contenido de todas las estaciones que, en conjunto con los audios, crean lo necesario para que las personas que quieran esperar en las instalaciones no se pierdan parte importante de los aprendizajes del recorrido.",
    ],
    audios: [
      {
        name: "AUDIO ANEXO",
        title: "Seguridad: Riesgos y vías de evacuación",
        media: audioAnexo2,
      },
    ],
  },
  station4: {
    title: "Estación 4",
    subtitle: "Período Arcaico",
    icon: station4Icon,
    route: "/station-4",
    next_route: "/station-5",
    paragraphs: ["Desde 11.000 años atrás hasta el 1500 D.C. ¿Quiénes habitaron estas tierras antes que el Estado Inca?"],
    audios: [
      {
        name: "AUDIO 8",
        title: "Período arcaico en el cajón del maipo",
        media: audio8,
      },
      {
        name: "AUDIO 9",
        title: "Período agroalfarero en el cajón del maipo",
        media: audio9,
      },
      {
        name: "AUDIO 10",
        title: "Encuentro con el imperio inca",
        media: audio10,
      },
    ],
  },
  station5: {
    title: "Estación 5",
    subtitle: "Vida Arcaica",
    icon: station5Icon,
    route: "/station-5",
    next_route: "/station-6",
    paragraphs: [
      "Son muchos los hallazgos que evidencian el estilo de vida de nuestros antepasados, aquí mencionamos las principales conclusiones de estas pruebas.",
    ],
    audios: [
      {
        name: "AUDIO 11",
        title: "Detalles del estilo de vida en los distintos períodos",
        media: audio11,
      },
    ],
  },
  station6: {
    title: "Estación 6",
    subtitle: "Tiempos Modernos",
    icon: station6Icon,
    route: "/station-6",
    next_route: "/station-7",
    paragraphs: ["Los últimos 485 años dan por conclusión lo interesante que es la historia del Cajón del Maipo en eventos tanto humanos como geológicos."],
    audios: [
      {
        name: "AUDIO 12",
        title: "Conquista, colonia y tiempos modernos en el cajón del maipo",
        media: audio12,
      },
      {
        name: "AUDIO 13",
        title: "Siglo XX, recursos naturales y extractivismo",
        media: audio13,
      },
    ],
  },
  station7: {
    title: "Estación 7",
    subtitle: "Presente y Futuro",
    icon: station7Icon,
    route: "/station-7",
    next_route: "/inter-station-3",
    paragraphs: [
      "Los tiempos cambian, han cambiado y muchas cosas deben cambiar. Mostrar el Cajón del Maipo y lo interesante de la historia de estas tierras, sin duda pondrá en valor las riquezas patrimoniales que hoy crean identidad y sentido de pertenencia entre sus habitantes.",
    ],
    audios: [
      {
        name: "AUDIO 14",
        title: "Presente y Futuro: el cajón del maipo camino a ser un geoparque mundial de la UNESCO",
        media: audio14,
      },
      {
        name: "AUDIO 15",
        title: "El proyecto geoparque cajón del maipo y sus geositios",
        media: audio15,
      },
    ],
  },
  interStation3: {
    title: "Variante 2",
    subtitle: "El Río Maipo",
    icon: stationV2Icon,
    route: "/inter-station-3",
    next_route: "/station-8",
    paragraphs: ["Desde Los Andes hasta el Pacífico, el río Maipo avanza, moldea y da vida."],
    audios: [
      {
        name: "AUDIO 16",
        title: "El Río Maipo",
        media: audio16,
      },
    ],
  },
  station8: {
    title: "Estación 8",
    subtitle: "Remociones en Masa",
    icon: station8Icon,
    route: "/station-8",
    next_route: "/farewell",
    paragraphs: [
      "Las remociones en masa son un evento cada vez más común en el planeta debido al cambio climático. Es importante aprender más de estos fenómenos, sobre todo antes de visitar lugares donde exista evidencia de estos desplazamientos.",
    ],
    audios: [
      {
        name: "AUDIO 17",
        title: "Riesgo de desastres y remociones en masa en el cajón del maipo",
        media: audio17,
      },
    ],
  },
};
