import React from "react";

const Content = ({ subtitle, paragraphs }) => {
  const displayParagraphs = () => {
    return paragraphs.map((item) => {
      return <p className='flow-text'>{item}</p>;
    });
  };

  return (
    <div className='row'>
      <div className='col s12'>
        <h5 className='left-align'>{subtitle}</h5>
        {displayParagraphs()}
      </div>
    </div>
  );
};

export default Content;
