import React from "react";

const Header = ({ title, icon }) => {
  return (
    <ul className='collection menu'>
      <li className='collection-item avatar' style={listStyle}>
        <img src={icon} alt={title} class="circle" />
        <span className='title' style={titleStyle}>
          {title}
        </span>
      </li>
    </ul>
  );
};

const titleStyle = {
  fontSize: "27px",
  position: "relative",
  top: "12px",
};

const listStyle = {
  minHeight: "65px",
};

export default Header;
