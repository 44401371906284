import React from "react";
import { stations } from "../../utils/functions";
import { Link } from "react-router-dom";

const StationMenuItems = () => {
  const stationArray = [
    stations["station1"],
    stations["interStation1"],
    stations["station2"],
    stations["interStation2"],
    stations["station3"],
    stations["station4"],
    stations["station5"],
    stations["station6"],
    stations["station7"],
    stations["interStation3"],
    stations["station8"],
  ];

  return stationArray.map((item) => {
    return (
      <li className='collection-item avatar'>
        <Link to={item.route}>
          <img src={item.icon} alt={item.title} class="circle" />
          <span className='title'>{item.title}</span>
          <p>
            <strong>{item.subtitle}</strong>
          </p>
        </Link>
        <Link to={item.route} className='secondary-content'>
          <i className='material-icons'>keyboard_arrow_right</i>
        </Link>
      </li>
    );
  });
};

export default StationMenuItems;
