import React from "react";

const AudioList = ({ audios }) => {
  return audios.map((item) => {
    return (
      <div className='row'>
        <div className='col s12'>
          <blockquote className='mt10'>{item.title}</blockquote>
          <audio controls src={item.media} preload='none'>
            Your browser does not support the
            <code>audio</code> element.
          </audio>
        </div>
      </div>
    );
  });
};

export default AudioList;
