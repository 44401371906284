import React from "react";
import Header from "../shared/Header";
import FooterBtns from "../shared/FooterBtns";
import AudioList from "./AudioList";
import Content from "./Content";

const Station = ({ data }) => {
  const { title, subtitle, icon, next_route, paragraphs, audios } = data;

  return (
    <div>
      <Header title={title} icon={icon} />
      <Content subtitle={subtitle} paragraphs={paragraphs} />
      <AudioList audios={audios} />
      <FooterBtns continueTo={next_route} />
    </div>
  );
};

export default Station;
