import React from "react";
import logo from "../../assets/images/logo.svg";

const Logo = () => {
  return <img src={logo} height='65' style={logoStyle} alt='Sureste' />;
};

const logoStyle = {
  marginTop: "7px",
};

export default Logo;
