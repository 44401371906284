import React from "react";
import { Link } from "react-router-dom";

function FooterBtns({ continueTo }) {
  return (
    <div className='row'>
      <div className='col s12'>
        <div className='center'>
          <Link to='/menu' className='waves-effect waves-light btn-small black m10r'>
            <i className='material-icons left'>menu</i>Menú
          </Link>
          <Link to={continueTo} className='waves-effect waves-light btn-small black'>
            <i className='material-icons right'>chevron_right</i>Continuar
          </Link>
        </div>
      </div>
    </div>
  );
}

export default FooterBtns;
